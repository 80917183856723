<template>
  <div class="page flex-col">
    <div class="share-head flex-col align-center" v-if="shareCodeInfo">
      <div class="head flex-row align-center">
        <img
          v-if="shareCodeInfo.userImage"
          :src="shareCodeInfo.userImage"
          alt=""
        />
        <img v-else src="@/assets/img/default_head.png" alt="" />
        <span class="userName">{{ shareCodeInfo.userName }}</span>
        <span class="txt">跟您分享了TA的广告投放订单</span>
      </div>
      <span class="desc1">大屏时代 - 专业LED大屏广告投放软件</span>
      <!-- <span class="desc2">新用户1元即可体验广告投放</span> -->
      <div
        v-if="currentScreenInfo"
        :class="['video-view', aliveCurrentScreenName]"
        :style="
          'background-image: url(' + currentScreenInfo.attachSub.length > 0
            ? currentScreenInfo.attachSub[0].image
            : currentScreenInfo.image + ')'
        "
      >
        <div class="video-title flex-row align-center">
          <img
            v-if="orderData.status == 0"
            src="../../assets/icon/share1.png"
            alt=""
          />
          <img
            v-if="orderData.status == 1 || orderData.status == 6"
            src="../../assets/icon/share1.png"
            alt=""
          />
          <img
            v-if="orderData.status == 2"
            src="../../assets/icon/share4.png"
            alt=""
          />
          <img
            v-if="orderData.status == 3"
            src="../../assets/img/hls_icon.png"
            alt=""
          />
          <img
            v-if="orderData.status == 4"
            src="../../assets/icon/share4.png"
            alt=""
          />
          <img
            v-if="orderData.status == 5"
            src="../../assets/icon/share5.png"
            alt=""
          />
          <span v-if="orderData.status == 0">待付款</span>
          <span v-if="orderData.status == 1 || orderData.status == 6"
            >审核中</span
          >
          <span v-if="orderData.status == 2">已取消</span>
          <span v-if="orderData.status == 3">投放中</span>
          <span v-if="orderData.status == 4">审核不通过</span>
          <span v-if="orderData.status == 5">已结束</span>
        </div>
        <div
          class="video-seat flex-row align-center"
          v-if="orderData.status == 3 || orderData.status == 5"
        >
          <video
            ref="myFlvVideo"
            :src="orderData.videoUrl"
            controls
            autoplay
            loop
            style="object-fit: contain"
          ></video>
        </div>
        <div
          v-if="orderData.status == 0"
          class="seat flex-col justify-center align-center"
        >
          <img src="../../assets/icon/share_tanhao.png" alt="" />
          <span>订单尚未支付</span>
        </div>
        <div
          v-if="orderData.status == 1 || orderData.status == 6"
          class="seat flex-col justify-center align-center"
        >
          <img src="../../assets/icon/share_tanhao.png" alt="" />
          <span>工作人员正在快马加鞭地</span>
          <span style="margin-top: 6rem">进行审核…</span>
        </div>
        <div
          v-if="orderData.status == 2"
          class="seat flex-col justify-center align-center"
        >
          <img src="../../assets/icon/share_tanhao.png" alt="" />
          <span>订单已被取消了</span>
        </div>
        <div
          v-if="orderData.status == 4"
          class="seat flex-col justify-center align-center"
        >
          <img src="../../assets/icon/share_tanhao.png" alt="" />
          <span>广告违规已被拒绝了</span>
        </div>
      </div>
    </div>
    <div class="orderTable flex-col" v-if="orderData.orderId">
      <el-divider class="divider"></el-divider>
      <div class="table-top flex-row justify-between">
        <div class="flex-col justify-between">
          <span>投放广告的屏幕</span>
          <span>{{ screenName }}</span>
        </div>
        <div
          v-if="orderData.videoDuration"
          class="flex-col justify-between align-center"
        >
          <span>视频时长</span>
          <span>{{ Number(orderData.videoDuration).toFixed(2) }}秒</span>
        </div>
        <div class="flex-col justify-between align-center">
          <span>投放方式</span>
          <span v-if="orderData.smart">智能投放</span>
          <span v-else>常规投放</span>
        </div>
        <div class="flex-col justify-between align-center">
          <span v-if="orderData.smart">实际投放次数</span>
          <span v-else>投放总次数</span>
          <span>{{ orderData.times }}次</span>
        </div>
        <div
          v-if="!orderData.smart"
          class="flex-col justify-between align-center"
        >
          <span>投放时间间隔</span>
          <span v-if="orderData.times > 1 && orderData.intervalMinute == 0"
            >不间隔，连续投放</span
          >
          <span v-else-if="orderData.times > 1"
            >每{{
              orderData.intervalMinute == 1440
                ? "1天"
                : orderData.intervalMinute >= 60
                ? orderData.intervalMinute / 60 + "小时"
                : orderData.intervalMinute + "分钟"
            }}投放1次</span
          >
          <span v-else>-</span>
        </div>
        <div
          v-if="orderData.smart"
          class="flex-col justify-between align-center"
        >
          <span>投放时间间隔</span>
          <span v-if="orderData.times > 1 && orderData.intervalMinute == 0"
            >不间隔，连续投放</span
          >
          <span v-else-if="orderData.times > 1 && orderData.smartDaysTimes > 1"
            >每{{ orderData.intervalMinute }}分钟投放1次</span
          >
          <span v-else-if="orderData.times > 1 && orderData.smartDaysTimes == 1"
            >每1天投放1次</span
          >
          <span v-else>-</span>
        </div>
        <div class="flex-col justify-between align-center">
          <span>开始投放时间</span>
          <span>{{ orderData.beginUnix | dateFormat }}</span>
        </div>
        <div class="flex-col justify-between align-end">
          <span>结束投放时间</span>
          <span>{{ orderData.endUnix | dateFormat }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="
        orderData.status == '1' ||
        orderData.status == '6' ||
        orderData.status == '3' ||
        orderData.status == '5'
      "
      class="explain-view align-center"
    >
      <div
        v-if="orderData.status == '1' || orderData.status == '6'"
        class="explain flex-row align-center"
      >
        订单正在审核中，距离第一次投放还有
        <div v-if="days > 0">
          <span>{{ days }}</span
          >天
        </div>
        <div v-if="hours > 0">
          <span>{{ hours }}</span
          >小时
        </div>
        <div v-if="minutes > 0">
          <span>{{ minutes }}</span
          >分
        </div>
        <div>
          <span>{{ seconds }}</span
          >秒
        </div>
      </div>
      <div
        v-if="orderData.status == '3' && orderData.hasAdPlaying"
        class="explain flex-row align-center"
      >
        正在投放中
      </div>
      <div
        v-if="orderData.status == '3' && !orderData.hasAdPlaying && orderData.hasNextAd"
        class="explain flex-row align-center"
      >
        距离下一次投放还有
        <div v-if="days > 0">
          <span>{{ days }}</span
          >天
        </div>
        <div v-if="hours > 0">
          <span>{{ hours }}</span
          >小时
        </div>
        <div v-if="minutes > 0">
          <span>{{ minutes }}</span
          >分
        </div>
        <div>
          <span>{{ seconds }}</span
          >秒
        </div>
      </div>
      <div
        v-if="orderData.status == '5' && orderData.isAdminHandOver"
        class="explain flex-row align-center"
      >
        此订单已被提前结束投放
      </div>
      <div
        v-if="orderData.status == '5' && !orderData.isAdminHandOver"
        class="explain flex-row align-center"
      >
        此订单已结束
      </div>
      <div
        v-if="orderData.status == '3' || orderData.status == '5'"
        class="monitor justify-center align-center" @click="goMonitor"
      >
        <img src="../../assets/icon/monitor_icon.png" alt="">
        <span>实时监控</span>
      </div>
    </div>
    <div
      v-if="
        orderData.status == '0' ||
        orderData.status == '1' ||
        orderData.status == '6'
      "
      class="ad-view flex-col"
    >
      <div class="ad-titlt flex-row align-center">
        <span>所有投放时间：</span>
      </div>
      <div
        class="ad-list"
        v-infinite-scroll="launchTimeLoad"
        :infinite-scroll-disabled="infiniteScrollDisabled"
        infinite-scroll-distance="1"
      >
        <span v-for="(item, index) in adList" :key="index" class="adItem">{{
          item.beginUnix | dateFormat
        }}</span>
      </div>
      <div v-if="adList.length > 18" class="bg"></div>
    </div>
    <div
      class="adTable"
      v-if="orderData.status == '3' || orderData.status == '5'"
    >
      <el-tabs v-model="currentTab" @tab-click="handleClick">
        <el-tab-pane label="全部投放" name="all" />
        <el-tab-pane :label="'待投放（' + status3Num + '）'" name="3" />
        <el-tab-pane :label="'投放成功（' + status6Num + '）'" name="6" />
        <el-tab-pane :label="'投放失败（' + status7Num + '）'" name="7" />
        <el-tab-pane v-if="orderData.status == '5'" :label="'投放取消（' + status2Num + '）'" name="2" />
      </el-tabs>
      <el-table
        class="el-table"
        :data="adList"
        max-height="300"
        v-loadMoreTable="launchTimeLoad"
      >
        <el-table-column prop="adId" align="left" width="250" label="投放编号">
        </el-table-column>
        <el-table-column prop="beginUnix" align="center" label="投放时间">
          <template v-slot="{ row }">
            <span>{{ row.beginUnix | dateFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderId" align="center" label="所属订单编号">
        </el-table-column>
        <el-table-column
          prop="status"
          align="right"
          width="200"
          label="投放状态"
        >
          <template v-slot="{ row }">
            <span>{{
              row.status == "0"
                ? "待支付"
                : row.status == "1"
                ? "审核中"
                : row.status == "2" || row.status == "4" || row.status == "5"
                ? "投放取消"
                : row.status == "3"
                ? "待投放"
                : row.status == "6"
                ? "投放成功"
                : row.status == "7"
                ? "投放失败"
                : row.status == "8"
                ? "待二次审核"
                : row.status == "9"
                ? "投放中"
                : "未知"
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div
      class="refund-tips"
      v-if="orderData.status == '3'"
    >
      <div class="refund-tips-titlt flex-row align-center">
        <span>退款说明</span>
      </div>
      <p>
        因设备或网络故障导致投放失败的部分，以及提前结束订单被取消投放的部分，我们将会在全部投放结束后24小时之内，按支付比例退款退时长，退款及时长原路退回。
      </p>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
  getOrderByShareCode,
  listAdByShareCode,
  getShareCodeInfo,
  getListDaping,
} from "@/api/request";

export default {
  data() {
    return {
      shareCodeInfo: "", // 分享者信息
      orderData: {}, // 订单数据
      screenName: "",
      screenList: [], // 屏幕列表
      aliveCurrentScreenName: "", // 订单所属屏幕
      currentTab: "all",
      currentPages: null,
      adList: [], // 投放中和已结束状态下的广告表格数据
      pageParams: {
        statusList: [],
        shareCode: "",
        Asc: true, // 升序排列
        pageLimit: {
          limit: 50,
          page: 1,
        },
      },
      status3Num: 0,
      status6Num: 0,
      status7Num: 0,
      status2Num: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      getOrderTimer: null,
      infiniteScrollDisabled: false,
    };
  },
  computed: {
    currentScreenInfo() {
      let findV = "";
      findV = this.screenList.find(
        (v) => v.name == this.aliveCurrentScreenName
      );
      return findV;
    },
  },
  created() {
    this.pageParams.shareCode = this.$route.query.code;
    this.infiniteScrollDisabled = false;
    this.getListDaping();
  },
  mounted() {},
  methods: {
    // 获取屏幕信息
    getListDaping() {
      getListDaping({}).then((res) => {
        this.screenList = res.list;
        this.$store.dispatch("screenListActions", res.list);
        this.getShareCodeInfo();
      });
    },
    // 获取分享码信息
    getShareCodeInfo() {
      getShareCodeInfo({
        share_code: this.pageParams.shareCode,
      }).then((res) => {
        this.shareCodeInfo = res;
        this.getOrderByShareCode();
      });
    },
    // 获取订单详情，分享码模式
    getOrderByShareCode() {
      getOrderByShareCode({
        share_code: this.pageParams.shareCode,
      }).then((res) => {
        this.orderData = res.order;
        this.aliveCurrentScreenName = res.order.name; // 设置订单所属屏幕
        // 查找屏幕名称
        if (res.order.name) {
          this.screenName = this.screenList.find(
            (v) => v.name == res.order.name
          ).remark;
        } else {
          this.screenName = "-";
        }
        // 需要广告的场景，请求广告数据
        if (
          res.order.status == "0" ||
          res.order.status == "1" ||
          res.order.status == "3" ||
          res.order.status == "5" ||
          res.order.status == "6"
        ) {
          this.getListAdEvent();
        }
        // 获取投放的广告表格统计信息
        if (res.order.status == "3" || res.order.status == "5") {
          this.getListAdNum(3);
          this.getListAdNum(6);
          this.getListAdNum(7);
        }
        if (res.order.status == "5") {
          this.getListAdNum(2);
        }
        // 审核中和投放中的订单，定时器请求订单信息
        if (
          res.order.status == "1" ||
          res.order.status == "6" ||
          res.order.status == "3"
        ) {
          this.getOrderTimer = setInterval(() => {
            this.setIntervalGetOrder();
          }, 1000);
        }
      });
    },
    // 定时器获取获取订单详情
    setIntervalGetOrder() {
      getOrderByShareCode({
        share_code: this.pageParams.shareCode,
      }).then((res) => {
        this.orderData = res.order;
        this.status3Num = res.order.adWaitPlay;
        this.status6Num = res.order.adSuccessPlay;
        this.status7Num = res.order.adFailPlay;
        this.status2Num = res.order.adCancel;
        if (res.order.status == "5") {
          this.clearTimer();
          return;
        }
        // 等待审核和非投放中并且有下次广告，计算倒计时
        if (
          res.order.status == "1" ||
          res.order.status == "6" ||
          (!res.order.hasAdPlaying && res.order.hasNextAd)
        ) {
          this.updateCountdown(res.order.nextAdSecond);
        }
      });
    },
    updateCountdown(nextAdSecond) {
      const diff = nextAdSecond * 1000;
      // 计算时间差
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      // 更新页面元素
      if (days > 0) {
        this.days = days;
      } else {
        this.days = 0;
      }
      if (days > 0 || hours > 0) {
        this.hours = hours;
      } else {
        this.hours = 0;
      }
      if (days > 0 || hours > 0 || minutes > 0) {
        this.minutes = minutes;
      } else {
        this.minutes = 0;
      }
      this.seconds = seconds;
    },
    // 投放的广告时间区域下拉到底分页加载
    launchTimeLoad() {
      if (this.pageParams.pageLimit.page < this.currentPages) {
        this.pageParams.pageLimit.page++;
        console.log(
          "触发了下拉到底，进行分页请求，请求页码为",
          this.pageParams.pageLimit.page
        );
        this.infiniteScrollDisabled = true;
        this.getPageListAdEvent();
      }
    },
    // 分页获取广告数据
    getPageListAdEvent() {
      listAdByShareCode(this.pageParams)
        .then((res) => {
          this.currentPages = res.pageResult.pages;
          this.adList = [...this.adList, ...res.adList];
          this.$nextTick(() => {
            this.infiniteScrollDisabled = false;
          });
        })
        .catch(() => {
          if (this.pageParams.pageLimit.page > 1) {
            this.pageParams.pageLimit.page--;
            this.infiniteScrollDisabled = false;
          }
        });
    },
    // 获取广告数据
    getListAdEvent() {
      this.pageParams.pageLimit.page = 1;
      listAdByShareCode(this.pageParams).then((res) => {
        this.currentPages = res.pageResult.pages;
        this.adList = res.adList;
        // 重新赋值点击状态的广告数量
        if (this.pageParams.statusList[0] == 3) {
          this.status3Num = res.pageResult.total;
        }
        if (this.pageParams.statusList[0] == 6) {
          this.status6Num = res.pageResult.total;
        }
        if (this.pageParams.statusList[0] == 7) {
          this.status7Num = res.pageResult.total;
        }
        if (this.pageParams.statusList[0] == 2) {
          this.status2Num = res.pageResult.total;
        }
      });
    },
    // 获取投放的广告表格统计信息
    getListAdNum(status) {
      const params = {
        statusList: status == 2 ? ["2", "4", "5"] : status == 3 ? ["3"] : status == 6 ? ["6"] : ["7"],
        shareCode: this.pageParams.shareCode,
        Asc: true,
        pageLimit: {
          limit: "1",
          page: "1",
        },
      };
      listAdByShareCode(params).then((res) => {
        if (status == 3) {
          this.status3Num = res.pageResult.total;
        }
        if (status == 6) {
          this.status6Num = res.pageResult.total;
        }
        if (status == 7) {
          this.status7Num = res.pageResult.total;
        }
        if (status == 2) {
          this.status2Num = res.pageResult.total;
        }
      });
    },
    handleClick(tab) {
      if (tab.name != "all") {
        if (tab.name == 3) {
          this.pageParams.statusList = ["3"];
          this.getListAdEvent();
          return;
        }
        if (tab.name == 6) {
          this.pageParams.statusList = ["6"];
          this.getListAdEvent();
          return;
        }
        if (tab.name == 7) {
          this.pageParams.statusList = ["7"];
          this.getListAdEvent();
        }
        if (tab.name == 2) {
          this.pageParams.statusList = ["2", "4", "5"];
          this.getListAdEvent();
        }
      } else {
        // 点击全部投放
        this.pageParams.statusList = [];
        this.getListAdEvent();
      }
    },
    clearTimer() {
      if (this.getOrderTimer) {
        clearInterval(this.getOrderTimer);
        this.getOrderTimer = null;
      }
    },
    goMonitor() {
      window.open("https://www.daping.cn/p2pVisitor/");
    }
  },
  beforeDestroy() {
    this.clearTimer();
  },
};
</script>

<style lang="scss" scoped>
.page {
  min-height: calc(100vh - 233rem);
  width: 1280rem;
  padding: 48rem 0 80rem;
  margin: 0 auto;

  .share-head {
    width: 100%;
    height: 894rem;
    background: url("../../assets/img/share_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .head {
      width: 100%;
      height: 102rem;
      padding-left: 40rem;

      img {
        width: 50rem;
        height: 50rem;
        border-radius: 10rem;
        object-fit: cover;
      }

      span {
        font-size: 20rem;
        line-height: 28rem;
        margin-left: 16rem;
      }

      .userName {
        color: #252927;
      }

      .txt {
        color: #939797;
      }
    }

    .desc1 {
      font-size: 30rem;
      line-height: 42rem;
      letter-spacing: 1px;
      background: linear-gradient(225deg, #4facfe 0%, #26cbd3 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-top: 50rem;
    }

    .desc2 {
      font-size: 28rem;
      color: #7c7e7f;
      line-height: 40rem;
      letter-spacing: 1px;
      margin-top: 18rem;
    }

    .video-view {
      width: 1024rem;
      background-color: #e2e2e2;
      position: relative;
      border-radius: 15rem;
      margin-top: 28rem;
      overflow: hidden;

      .video-title {
        width: 100%;
        height: 56rem;
        background: rgba(39, 39, 39, 0.55);
        padding: 14rem 0 12rem 18rem;
        position: absolute;
        top: 0;
        left: 0;

        img {
          width: 30rem;
          height: 30rem;
        }

        span {
          font-size: 20rem;
          color: #ffffff;
          line-height: 28rem;
          margin-left: 10rem;
        }
      }

      .video-seat {
        background-color: #000000;
        z-index: 2;

        video {
          width: 100%;
          height: 100%;
        }
      }

      .seat {
        z-index: 2;
        background: #222222;

        span {
          font-size: 16rem;
          color: #ffffff;
          line-height: 22rem;
        }

        img {
          width: 32rem;
          height: 32rem;
          margin-bottom: 18rem;
        }
      }
    }

    .default {
      // background: url("../../assets/img/shareVideo_bg.png");
      background: url("../../assets/img/hlsVideo_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 622rem;
      .video-seat,
      .seat {
        width: 438rem;
        height: 292rem;
        position: absolute;
        top: 266rem;
        left: 290rem;
      }
      .video-seat {
        video {
          object-fit: fill !important;
        }
      }
    }

    .yihua,.demo {
      background: url("../../assets/img/hlsVideo_yhbg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 618rem;
      .video-seat,
      .seat {
        width: 396rem;
        height: 297rem;
        position: absolute;
        top: 288rem;
        left: 314rem;
      }
    }
    .xinghu {
      background: url("../../assets/img/hlsVideo_yhbg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 618rem;
      .video-seat,
      .seat {
        width: 438rem;
        height: 222rem;
        position: absolute;
        top: 266rem;
        left: 290rem;
      }
    }
    .test {
      background: url("../../assets/img/hlsVideo_yhbg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 618rem;
      .video-seat,
      .seat {
        width: 396rem;
        height: 297rem;
        position: absolute;
        top: 288rem;
        left: 314rem;
      }
    }
  }

  .orderTable {
    width: 100%;
    margin-bottom: 40rem;
    margin-top: 40rem;
    padding: 20rem 0 15rem;
    background-color: #fff;
    position: relative;

    .table-top {
      padding: 0 32rem 0 48rem;

      div {
        height: 81rem;

        span:nth-child(1) {
          font-size: 16rem;
          color: #a0a2a0;
        }

        span:nth-child(2) {
          font-size: 16rem;
          color: #252927;
        }

        .ad-img {
          width: 166rem;
          height: 102rem;
          background: #f6f6f6;
          border-radius: 8rem;
          cursor: pointer;
          overflow: hidden;
          position: relative;

          .logo_seize {
            position: absolute;
            top: 31rem;
            left: 63rem;
            width: 41rem;
            z-index: 1;
          }

          .img {
            // position: absolute;
            // top: 0;
            // left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .divider {
      position: absolute;
      left: 0;
      top: 60rem;
    }

    .table-bottom {
      width: 100%;
      margin-top: 55rem;
      padding: 0 35rem;

      .left-desc {
        span {
          font-size: 16rem;
          color: #252927;
          line-height: 22rem;
          margin-top: 12rem;
        }
      }

      .right-desc {
        .preferential {
          font-size: 16rem;
          color: #252927;

          span {
            color: #2bb3e3;
          }

          margin-bottom: 10rem;
        }

        .total {
          font-size: 18rem;
          color: #252927;

          span {
            color: #2bb3e3;
          }
        }
      }
    }
  }
  .explain-view {
    width: 100%;
    padding: 15rem 48rem;
    background: #ffffff;
    margin-bottom: 40rem;
    .explain {
      font-size: 16rem;
      color: #252927;
      line-height: 22rem;
      span {
        color: #2bb3e3;
      }
    }
    .monitor {
      width: 87rem;
      height: 26rem;
      background: #FFFFFF linear-gradient(225deg, #ECF8FF 0%, #E5FCFC 100%);
      border-radius: 14rem;
      margin-left: 10rem;
      cursor: pointer;
      img {
        width: 14rem;
        height: 14rem;
      }
      span {
        font-size: 12rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #2BB3E3;
        line-height: 17rem;
        margin-left: 5rem;
      }
    }
  }
  .ad-view {
    width: 100%;
    padding: 37rem 8rem 0 48rem;
    background: #ffffff;

    .ad-titlt {
      height: 18rem;
      line-height: 18rem;
      border-left: 3rem solid #3bbcf9;
      padding-left: 14rem;
      margin-bottom: 13rem;
      font-size: 18rem;
      color: #252927;

      .desc {
        padding: 0 8rem;
        height: 32rem;
        background: rgba(234, 145, 35, 0.08);
        border-radius: 12rem;
        text-align: center;
        line-height: 32rem;
        font-size: 15rem;
        color: #da7f0d;
      }

      .grey {
        background-color: rgba(152, 152, 152, 0.08);
        color: #7c7c7c;
      }
    }

    .ad-list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-height: 130rem;
      overflow-y: auto;
      padding-bottom: 20rem;

      .adItem {
        font-size: 16rem;
        color: #3b3e3c;
        margin: 12rem 28rem 0 0;
      }
    }

    .bg {
      position: relative;
      bottom: 23rem;
      width: 98%;
      height: 23rem;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.5) 0%,
        #ffffff 100%
      );
    }
  }

  .adTable {
    width: 100%;
    // padding-bottom: 20rem;
    background-color: #fff;

    .table-tips-grey {
      width: 98%;
      line-height: 25rem;
      background: rgba(152, 152, 152, 0.08);
      border-radius: 12rem;
      padding: 9rem 9rem 9rem 14rem;
      margin-left: 1%;
      position: relative;

      .tips-text {
        width: 98%;

        .tips-icon {
          width: 25rem;
          height: 25rem;
          margin-right: 10rem;
        }

        span {
          color: #7c7c7c;
          width: 97%;
        }
      }
      .close-icon {
        width: 30rem;
        height: 26rem;
        background: rgba(152, 152, 152, 0.16);
        border-radius: 0rem 12rem 0rem 12rem;
        text-align: center;
        line-height: 26rem;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;

        img {
          width: 22rem;
        }
      }
    }
    .el-tabs {
      padding-left: 36rem;
    }

    .el-table {
      width: 100%;
      // margin-left: 2%;
    }
  }
  .refund-tips {
      width: 100%;
      padding: 40rem 40rem;
      background-color: #fff;
      margin-top: 40rem;

      .refund-tips-titlt {
          height: 18rem;
          line-height: 18rem;
          border-left: 3rem solid #3bbcf9;
          padding-left: 14rem;
          margin-bottom: 25rem;
          font-size: 18rem;
          color: #252927;
      }

      p {
          color: #929492;
      }
  }
}

::v-deep .el-tabs__item {
  height: 62rem;
  line-height: 62rem;
  font-size: 16rem;
}

::v-deep .el-table thead {
  font-size: 16rem;
  color: #a4a6a4;
}

::v-deep .cell {
  font-weight: 400;
}

::v-deep .is-left {
  padding-left: 38rem;
}

::v-deep .is-right {
  padding-right: 38rem;
}

::v-deep .el-table td.el-table__cell div {
  font-size: 16rem;
  color: #3b3e3c;
}

::v-deep .el-table th.el-table__cell.is-leaf {
  // border: none;
  border-bottom: 2rem solid #f9f9f9;
}

::v-deep .el-table__row > td {
  // border: none;
  border-bottom: 2rem solid #f9f9f9;
}

::v-deep .el-table::before {
  height: 0rem;
}

::v-deep .el-divider {
  height: 2rem;
  background-color: #f9f9f9;
}

::v-deep .el-divider--horizontal {
  margin: 0;
}

::v-deep .el-tabs__header {
  margin: 0 0 15rem;
}

// ::v-deep .el-tabs {
//   border-bottom: 2rem solid #f9f9f9;
// }
</style>
